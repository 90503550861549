import { createAction } from "redux-actions";
import { apiGet, apiPost } from "../../api/index";
import {
  urlAccountLinksProtected,
  urlAccountLinksResumeProtected,
  urlAccountLinksDetailsProtected,
} from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.companyCode !== undefined)
      filter += "&companyCode=" + filters.companyCode;
    if (filters.month !== undefined) filter += "&month=" + filters.month;
    if (filters.year !== undefined) filter += "&year=" + filters.year;
    if (filters.status !== undefined) filter += "&status=" + filters.status;
    if (filters.showError !== undefined && filters.status === undefined)
      filter += "&showError=" + filters.showError;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

const generateFilterResumeQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    // if (filters.accountGeneratedUuid !== undefined)
    //   filter += "&accountGeneratedUuid=" + filters.accountGeneratedUuid;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

const generateFilterDetailsQuery = ({ filters, page, pageSize, sort }) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.uuid !== undefined)
      filter += "&accountGeneratedUuid=" + filters.uuid;
    if (filters.account !== undefined) filter += "&account=" + filters.account;
    if (filters.ceco !== undefined) filter += "&ceco=" + filters.ceco;
  }

  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list accounts.
 */
export const filterAccount = createAction("FILTER_ACCOUNTS", (filter, param) =>
  apiGet(urlAccountLinksProtected + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list accounts resume.
 */
export const filterResumeAccount = createAction(
  "FILTER_RESUME_ACCOUNTS",
  (filter, param) => apiGet(urlAccountLinksResumeProtected, param, true)()
);

/**
 * Filter list accounts.
 */
export const generate = createAction("GENERATE_ACCOUNT", (obj) =>
  apiPost(urlAccountLinksProtected, obj, undefined, true)()
);

/**
 * Filter list accounts.
 */
export const filterDetails = createAction("FILTER_DETAILS", (filter, param) =>
  apiGet(
    urlAccountLinksDetailsProtected + generateFilterDetailsQuery(filter),
    param,
    true
  )()
);
