import { handleActions } from "redux-actions";

import { SET_LANGUAGE, CLEAN_NAV, SET_DATA_LINE } from "../../constants/index";

export const nav = handleActions(
  {
    [CLEAN_NAV]: () => ({}),
    [SET_LANGUAGE]: (state, action) => ({
      ...state,
      language: action.payload,
    }),
    [SET_DATA_LINE]: (state, action) => ({
      ...state,
      line: action.payload,
    }),
  },
  {}
);
