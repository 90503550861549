import styled from "styled-components";

export const Container = styled.div`
  font-size: 1rem;
  background-color: #203b34;
  color: #fff;
  border-right: 1px solid #fff;
  border-radius: 5px;
  padding: 20px;
`;

export const LabelRes = styled.div`
  font-size: 1rem;
  color: #f9ae17;
  margin-right: 5px;
`;

export const ValueRes = styled.div`
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  margin-right: 5px;
`;
