import React, { useState } from "react";
import List from "../accountList";
import Filter from "../accountFilter";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ButtonShowError } from "./accountFilterList.styled";

const queryClient = new QueryClient();

const AccountFilterListContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.accountLinks.title") },
  ];
  const [filters, setFilters] = useState({ showError: false });
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters({ showError });
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const goToRegister = () => {
    navigate(PATHS.GENERATE_ACCOUNT_LINKS);
  };

  const changeStatusError = (event) => {
    const f = { ...filters };
    f.showError = event;
    setFilters(f);
    setShowError(event);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.accountLinks.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11 d-flex justify-content-end align-items-center">
          <ButtonShowError>{t("forms.accountLinks.showError")}</ButtonShowError>
          <input
            type="checkbox"
            checked={showError}
            onChange={(e) => changeStatusError(e.target.checked)}
          ></input>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 text-center">
          <button className="btn btn-secondary" onClick={() => goToRegister()}>
            {t("forms.accountLinks.buttons.new")}
          </button>
        </div>
      </div>
    </>
  );
};

AccountFilterListContainer.propTypes = {};

export default AccountFilterListContainer;
