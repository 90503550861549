import React from "react";
import AccountDetailList from "../../../components/user/accountlink/accountDetailFilter";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const AccountDetailPage = () => {
  let { uuid, ceco, account } = useParams();
  return (
    <Layout
      body={<AccountDetailList uuid={uuid} ceco={ceco} account={account} />}
    />
  );
};

AccountDetailPage.propTypes = {};

export default AccountDetailPage;
