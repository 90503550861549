import React, { useState } from "react";
import * as AccountServices from "../../../../services/AccountServices";
import PaginateList from "../../../utils/PaginateList";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { checkBootstrapSizeBreak } from "../../../../utils/device/checkDevice";
import {
  ButtonAction,
  Icon,
  ContainerTooltip,
  ErrorIcon,
} from "./accountList.styled";
import { useNavigate } from "react-router-dom";
import {
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_GENERATED,
  ACCOUNT_STATUS_DELIVERED,
  ACCOUNT_STATUS_ERROR,
} from "../../../../constants/index";
import { Tooltip } from "react-tooltip";

const AccountListContainer = ({ filters }) => {
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      header: t("forms.accountLinks.table.date"),
      accessorKey: "dateGenerated",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy HH:mm"),
    },
    {
      header: t("forms.accountLinks.table.companyCode"),
      accessorKey: "companyCode",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.accountLinks.table.period"),
      accessorKey: "month",
      cell: (info) => {
        if (info.row.original.month) {
          return `${info.row.original.month}/${info.row.original.year}`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.accountLinks.table.status"),
      accessorKey: "status",
      cell: (info) => {
        if (info.row.original.status) {
          const type = ACCOUNT_STATUS.find(
            (st) => st.code === info.row.original.status
          );
          if (type.code === ACCOUNT_STATUS_ERROR.code) {
            return (
              <>
                <span
                  data-tooltip-id={`tooltip_error_${info.row.original.uuid}`}
                >
                  <ErrorIcon className="fa-solid fa-triangle-exclamation"></ErrorIcon>
                  {type ? t(type.label) : info.getValue()}
                </span>
                <Tooltip id={`tooltip_error_${info.row.original.uuid}`}>
                  <ContainerTooltip>
                    {info.row.original.errorOnGenerated}
                  </ContainerTooltip>
                </Tooltip>
              </>
            );
          } else {
            return type ? t(type.label) : info.getValue();
          }
        }
        return "-";
      },
    },
    {
      header: t("forms.accountLinks.table.sended"),
      accessorKey: "sendToSAP",
      cell: (info) => {
        if (info.row.original.sendToSAP) {
          return `SI`;
        }
        return "NO";
      },
    },
    {
      header: t("forms.accountLinks.table.balanceOk"),
      accessorKey: "balanceOk",
      cell: (info) => {
        if (info.row.original.balanceOk) {
          return `SI`;
        }
        return "NO";
      },
    },
    {
      header: "",
      accessorKey: "ac",
      size: 50,
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const renderAction = (data) => {
    return (
      <>
        {(data?.status === ACCOUNT_STATUS_GENERATED.code ||
          data?.status === ACCOUNT_STATUS_DELIVERED.code) && (
          <div className="dropdown text-center">
            <ButtonAction
              onClick={() => viewResume(data)}
              title={t("forms.accountLinks.labels.viewResume")}
            >
              <Icon className="fa-solid fa-arrow-right-long"></Icon>
            </ButtonAction>
          </div>
        )}
      </>
    );
  };

  const viewResume = async (version) => {
    navigate(`/user/accountLinks/resume/${version.uuid}`);
  };

  const listData = async (filter) => {
    const result = await AccountServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;
  return (
    <PaginateList
      refresh={forceRefresh}
      filters={filters}
      columns={columns}
      toList={listData}
      hideColum={hideColumn}
      resize={resize}
      resetRefresh={() => setForceRefresh(false)}
    ></PaginateList>
  );
};

export default AccountListContainer;
