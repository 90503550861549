import * as Yup from "yup";

export const dataModel = {
  companyCode: "",
  month: "",
  year: "",
  status: "",
};

export const validationDataModel = Yup.object().shape({
  companyCode: Yup.string().optional(""),
  month: Yup.number().optional(""),
  year: Yup.number().optional(""),
  status: Yup.string().optional(""),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
