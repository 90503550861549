import { createAction } from "redux-actions";
import { SET_LANGUAGE, SET_DATA_LINE } from "../../constants/index";
import { apiGet } from "../../api/index";
import { urlGetLoginItemTemp, urlCompanyProtected } from "../../api/urls";

/**
 * Set TimeZone.
 */
export const setLanguage = createAction(SET_LANGUAGE, (data) => data);

/**
 * Get item.
 */
export const getLoginItem = createAction("GET_ITEM", (token) =>
  apiGet(urlGetLoginItemTemp, [token], false)()
);

/**
 * Get companies.
 */
export const getAllCompanies = createAction("GET_COMPANIES", (obj) =>
  apiGet(urlCompanyProtected, undefined, undefined)()
);

/**
 * Set TimeZone.
 */
export const setDataLine = createAction(SET_DATA_LINE, (data) => data);
