import React, { useState } from "react";
import List from "../accountResumeList";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const AccountFilterListContainer = ({ uuid }) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.accountLinks.title"), path: PATHS.ACCOUNT_LINKS },
    { label: t("forms.accountLinks.titleResume") },
  ];

  const [filters, setFilters] = useState(undefined);

  // const handlerCleanFilter = async (resetForm) => {
  //   resetForm();
  //   setFilters(undefined);
  // };

  // const handlerOnSubmitFilter = (values) => {
  //   let newFilter = {};
  //   const valuesFilters = Object.entries(values);
  //   valuesFilters.forEach((value) => {
  //     if (value[1] !== undefined && value[1].toString().length > 0) {
  //       newFilter[value[0]] = value[1];
  //     }
  //   });

  //   setFilters(newFilter);
  // };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.accountLinks.titleResume")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          {/* <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          /> */}
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} uuid={uuid} />
          </QueryClientProvider>
        </div>
      </div>
    </>
  );
};

AccountFilterListContainer.propTypes = {};

export default AccountFilterListContainer;
