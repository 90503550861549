import React, { useState, useEffect } from "react";
import * as AccountServices from "../../../../services/AccountServices";
import * as GeneralServices from "../../../../services/GeneralServices";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ResumeView from "./accountResumeList.view";

const AccountResumeListContainer = ({ filters, uuid }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    AccountServices.listResumeFilter({}, uuid).then((result) => {
      if (result) {
        setData(result);
      }
    });
  }, [uuid]);

  const handleOnSelectLine = async (line, ceco) => {
    await GeneralServices.setLine(line);
    navigate(`/user/accountLinks/details/${ceco}/${uuid}/${line.account}`);
  };

  return (
    <ResumeView data={data} onSelectLine={handleOnSelectLine}></ResumeView>
  );
};

export default AccountResumeListContainer;
