import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GenerateForm from "./accountGenerateForm.view";
import * as GeneralServices from "../../../../services/GeneralServices";
import * as AccountServices from "../../../../services/AccountServices";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const AccountGenerateFormContainer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [initValues, setInitValues] = useState({});
  const [companies, setCompanies] = useState(null);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  useEffect(() => {
    if (!companies && companiesSelected.length === 0) {
      GeneralServices.getCompanies().then((result) => {
        setCompanies(arrayOrder(result, "code"));
      });
    }
  }, []);

  const handleSubmit = async (values) => {
    let title = "";
    let text = "";
    let icon = "";
    let okAction = () => {
      navigate(PATHS.ACCOUNT_LINKS);
    };
    try {
      let result;
      if (companiesSelected && companiesSelected.length > 0) {
        result = await AccountServices.generateAccounts(
          values,
          companiesSelected
        );
        if (result) {
          title = t("forms.accountLinks.message.generate.title");
          text = t("forms.accountLinks.message.generate.success");
          icon = "success";
        }
      } else {
        title = t("forms.accountLinks.message.generate.title");
        text = t("forms.accountLinks.message.generate.errorSelectCompany");
        icon = "error";
        okAction = () => {};
      }
      showDialog(title, text, icon, false, "OK", false, okAction);
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const cancel = async () => {
    navigate(PATHS.ACCOUNT_LINKS);
  };

  const handlerOnSelectCompany = async (company) => {
    const newSelected = [...companiesSelected];
    newSelected.push(company);
    const newAllowed = [...companies];
    const index = newAllowed.findIndex((obj) => obj.code === company.code);
    if (index !== -1) {
      newAllowed.splice(index, 1);
    }
    setCompaniesSelected(arrayOrder(newSelected, "code"));
    setCompanies(arrayOrder(newAllowed, "code"));
  };

  const handlerOnSelectAllCompany = async () => {
    const newSelected = [...companiesSelected, ...companies];
    setCompaniesSelected(arrayOrder(newSelected, "code"));
    setCompanies([]);
  };

  const handlerOnDeleteCompany = async (company) => {
    const newAllowed = [...companies];
    newAllowed.push(company);

    const newSelected = [...companiesSelected];
    const index = newSelected.findIndex((obj) => obj.code === company.code);
    if (index !== -1) {
      newSelected.splice(index, 1);
    }
    setCompaniesSelected(arrayOrder(newSelected, "code"));
    setCompanies(arrayOrder(newAllowed, "code"));
  };

  const handlerOnDeleteAllCompany = async () => {
    const newSelected = [...companiesSelected, ...companies];
    setCompaniesSelected([]);
    setCompanies(arrayOrder(newSelected, "code"));
  };

  const arrayOrder = (array, sortBy) => {
    return array.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return -1;
      if (a[sortBy] > b[sortBy]) return 1;
      return 0;
    });
  };

  return (
    <GenerateForm
      initialValues={initValues}
      // dataForm={dataForm}
      onSubmit={handleSubmit}
      cancelAction={cancel}
      companies={companies}
      companiesSelected={companiesSelected}
      onSelect={handlerOnSelectCompany}
      onDelete={handlerOnDeleteCompany}
      onSelectAll={handlerOnSelectAllCompany}
      onDeleteAll={handlerOnDeleteAllCompany}
    />
  );
};

AccountGenerateFormContainer.propTypes = {};

export default AccountGenerateFormContainer;
