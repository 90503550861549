const PATHS = {};

PATHS.LOGIN = "/login";
PATHS.HOME = "/user/home";
PATHS.ACCOUNT_LINKS = "/user/accountLinks";
PATHS.VIEW_RESUME_ACCOUNT_LINKS = "/user/accountLinks/resume/:uuid";
PATHS.GENERATE_ACCOUNT_LINKS = "/user/accountLinks/generate";
PATHS.DETAILS_ACCOUNT_LINKS = "/user/accountLinks/details/:ceco/:uuid/:account";

PATHS.NEW_EMPLOYEE = "/employee/new";
PATHS.REQUEST_EMPLOYEES = "/employee/new";

PATHS.EXTERNAL_CONFIRM_LOGIN = "/authConfirm/:token";

export default PATHS;
