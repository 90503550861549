import styled, { css } from "styled-components";

export const IconStyled = styled.i`
  width: 30px;
  font-size: 1rem !important;
  margin-right: 5px;
`;

export const IconArrow = styled.i`
  ${({ theme, $active }) => {
    if ($active) {
      return css`
        width: 30px;
        /* color: ${theme ? theme.sidebar.colorLink : "#fff"}; */

        &:hover {
          color: ${theme ? theme.sidebar.colorLinkHover : "#fff"};
        }
      `;
    } else {
      return css`
        width: 30px;
        background: transparent;
        color: #707070;
        cursor: initial !important;

        &:hover {
          color: color: #707070;;
        }
      `;
    }
  }}
`;

export const OffCanvasHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0 !important;
  z-index: 99999 !important;
`;

export const ButtonOption = styled.button`
  ${({ theme, $active }) => {
    if ($active) {
      return css`
        letter-spacing: 1px;
        font-size: 1rem;
        border: 0px;
        background: transparent;
        color: ${theme ? theme.sidebar.colorLink : "#fff"};
        width: 100%;
        justify-content: start;
        display: flex;
        align-items: center;
        padding: 10px 15px;

        &:hover {
          color: ${theme ? theme.sidebar.colorLinkHover : "#fff"};
        }
      `;
    } else {
      return css`
        font-size: 1rem;
        letter-spacing: 1px;
        border: 0px;
        background: transparent;
        color: #fff !important;
        cursor: initial !important;
        padding: 10px 15px;

        &:hover {
          color: color: #fff !important;
        }
      `;
    }
  }}
`;

export const ButtonOption2 = styled.button`
  ${({ theme, $active }) => {
    if ($active) {
      return css`
        letter-spacing: 1px;
        font-size: 1.2rem;
        border: 0px;
        background: transparent;
        color: ${theme ? theme.sidebar.colorLink : "#fff"};
        width: 100%;
        justify-content: start;
        display: flex;
        align-items: center;
        padding: 10px 15px;

        &:hover {
          color: "#fff";
        }
      `;
    } else {
      return css`
        font-size: 1.2rem;
        letter-spacing: 1px;
        border: 0px;
        background: transparent;
        color: #fff !important;
        cursor: initial !important;
        padding: 10px 15px;

        &:hover {
          color: color: #fff !important;
        }
      `;
    }
  }}
`;

export const SubButtonOption = styled.button`
  ${({ theme, $active }) => {
    if ($active) {
      return css`
        border: 0px;
        font-family: "Conthrax";
        letter-spacing: 1px;
        font-size: 0.65rem;
        background: transparent;
        color: ${theme ? theme.sidebar.colorLink : "#fff"};
        padding-left: 35px;

        &:hover {
          color: ${theme ? theme.sidebar.colorLinkHover : "#fff"};
        }
      `;
    } else {
      return css`
        border: 0px;
        font-family: 'Conthrax';
        letter-spacing: 1px;
        font-size: 0.65rem;
        background: transparent;
        color: #707070;
        cursor: initial !important;
        padding-left: 35px;

        &:hover {
          color: color: #707070;
        }
      `;
    }
  }}
`;

export const Logo = styled.img`
  width: 80%;
  /* max-width: ${({ offcanvas }) => (offcanvas ? "100px" : "120px")}; */
  /* margin-top: ${({ offcanvas }) => (offcanvas ? "-30px" : "initial")}; */
`;

export const NavLink = styled.li`
  display: block;
  padding: 0.5rem 0rem;
  color: #fff !important;
  border-bottom: 1px solid #f9ae17;

  &:hover {
    color: #fff !important;
    background: #f9ae17;
  }
`;

export const NavLinkChild = styled.li`
  display: block;
  padding: 0.5rem 0rem;
  /* background: #0000006e; */
`;
