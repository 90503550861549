const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${
  process.env.REACT_APP_API_HOST
}${
  process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ""
}/api/`;

/** AUTH **/
export const urlGetLoginItemTemp = `${urlBase}v1/items/login`;

export const urlRefreshToken = `${urlBase}v1/user/refresh-token`;
export const urlLogout = `${urlBase}v1/user/logout`;
export const urlGetProfile = `${urlBase}v1/user-s/profile`;
export const urlSetNotification = `${urlBase}v1/user-s/save-subscription`;

export const urlPreRegisterEmployee = `${urlBase}v1/employeeRequest-s/preRegister`;
export const urlAccountLinksProtected = `${urlBase}v1/accountLink-s`;
export const urlAccountLinksResumeProtected = `${urlBase}v1/accountLink-s/resume`;
export const urlAccountLinksDetailsProtected = `${urlBase}v1/accountLink-s/listAccounts`;

export const urlCompanyProtected = `${urlBase}v1/company-s`;
