import React from "react";
import AccountGenerate from "../../../components/user/accountlink/accountGenerateForm";
import Layout from "../../../components/layouts/verticalLayout";

const AccountGeneratePage = () => {
  return <Layout body={<AccountGenerate />} />;
};

AccountGeneratePage.propTypes = {};

export default AccountGeneratePage;
