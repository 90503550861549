import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Title, Container } from "./login.styled";
import Languages from "../layouts/commons/header/components/languages";

const LoginForm = (props) => {
  const { gotoLogin } = props;
  const { t } = useTranslation();

  return (
    <div className="login-page row no-margin">
      <div className="col-12">
        <div className="row justify-content-center">
          <div className="col-10 mt-2 d-flex justify-content-end">
            <Languages />
          </div>
        </div>
        <Container className="row justify-content-center align-items-center">
          <div className="col-10">
            <div className="row justify-content-center mt-5">
              <div className="col-10 mt-2 d-flex justify-content-center">
                <Title>{t("title")}</Title>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary"
                    onClick={() => gotoLogin()}
                  >
                    {t("login")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  gotoLogin: PropTypes.func,
};

export default LoginForm;
