import { store } from "../store/index";
import {
  filterAccount,
  filterResumeAccount,
  generate,
  filterDetails,
} from "../store/actions/AccountActions";

/**
 * List account links
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterAccount(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List account resume links
 */
export const listResumeFilter = async (filter, uuid) => {
  const result = await store.dispatch(filterResumeAccount(filter, [uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Generate accounts
 */
export const generateAccounts = async (values, companies) => {
  const obj = { ...values };
  obj.companies = companies.map((company) => company.code);
  const result = await store.dispatch(generate(obj));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List account details
 */
export const listDetailsFilter = async (filter) => {
  const result = await store.dispatch(filterDetails(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
