import React from "react";
import AccountResumeList from "../../../components/user/accountlink/accountResumeFilter";
import Layout from "../../../components/layouts/verticalLayout";
import { useParams } from "react-router-dom";

const AccountResumePage = () => {
  let { uuid } = useParams();
  return <Layout body={<AccountResumeList uuid={uuid} />} />;
};

AccountResumePage.propTypes = {};

export default AccountResumePage;
