import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CecoContainer,
  Title,
  Body,
  SubTitle,
  Data,
  SubTitleFooter,
} from "./accountResumeList.styled";
import moment from "moment-timezone";
import { centsToMoney } from "../../../../utils/formats/number";

const PdfView = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  const renderLines = (lines) => {
    return lines.map((line) => {
      return (
        <div className="row" key={line.uuid}>
          <Data className="col">
            {moment(new Date(line.dateLine)).format("DD-MM-yyyy")}
          </Data>
          <Data className="col text-end">
            {line.type === "D" ? `${centsToMoney(line.amount)}€` : ""}
          </Data>
          <Data className="col">{line.type === "D" ? line.account : ""}</Data>
          <Data className="col-3">{line.concept}</Data>
          <Data className="col">{line.type === "H" ? line.account : ""}</Data>
          <Data $last className="col text-end">
            {line.type === "H" ? `${centsToMoney(line.amount)}€` : ""}
          </Data>
        </div>
      );
    });
  };

  const renderCecoLines = () => {
    return data.map((dataCeco) => {
      return (
        <div className="row mb-4 html2pdf__page-break" key={dataCeco.ceco}>
          <CecoContainer className="col-12">
            <div className="row">
              <Title className="col-12">{dataCeco.ceco}</Title>
            </div>
            <div className="row">
              <Body className="col-12">
                <div className="row">
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.date")}
                  </SubTitle>
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.amountDebe")}
                  </SubTitle>
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.ctaDebe")}
                  </SubTitle>
                  <SubTitle className="col-3">
                    {t("forms.accountLinks.labels.concept")}
                  </SubTitle>
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.ctaHaber")}
                  </SubTitle>
                  <SubTitle $last className="col">
                    {t("forms.accountLinks.labels.amountHaber")}
                  </SubTitle>
                </div>
                {renderLines(dataCeco.lines)}
                <div className="row">
                  <SubTitleFooter $last className="col">
                    {t("forms.accountLinks.labels.totalDebe")}
                  </SubTitleFooter>
                  <SubTitleFooter $last className="col text-end">
                    {`${centsToMoney(dataCeco.totalDebe)}€`}
                  </SubTitleFooter>
                  <SubTitleFooter $last className="col"></SubTitleFooter>
                  <SubTitleFooter $last className="col-3"></SubTitleFooter>
                  <SubTitleFooter $last className="col">
                    {t("forms.accountLinks.labels.totalHaber")}
                  </SubTitleFooter>
                  <SubTitleFooter $last className="col text-end">
                    {`${centsToMoney(dataCeco.totalHaber)}€`}
                  </SubTitleFooter>
                </div>
              </Body>
            </div>
          </CecoContainer>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row no-margin">
        <div className="col-12 mb-3">{data && renderCecoLines()}</div>
      </div>
    </>
  );
};

PdfView.propTypes = {
  data: PropTypes.any,
};

export default PdfView;
