/**
 * Delete Zero
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const deleteZero = (n) =>
  parseInt(n.toString().split(".")[1]) === 0 ? parseInt(n) : n;

/**
 * Number is float
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const isFloat = (n) => n % 1 !== 0;

/**
 * Get money from cents
 *
 * @param  {String} crypto
 * @param  {String} satoshi
 * @return {Promise}
 */
export const centsToMoney = (cents) => {
  const currency = isFloat(cents / 10 ** 2)
    ? deleteZero((cents / 10 ** 2).toFixed(2))
    : cents / 10 ** 2;

  return currency;
};
