import React, { useState } from "react";
import List from "../accountDetailList";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLineSelector } from "../../../../store/selectors/navSelector";
import { Container, LabelRes, ValueRes } from "./accountDetailFilter.styled";
import { centsToMoney } from "../../../../utils/formats/number";
import { OPERATION_TYPES } from "../../../../constants/index";

const queryClient = new QueryClient();

const AccountDetailFilterListContainer = ({ uuid, ceco, account }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const resume = useSelector(getLineSelector);

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.accountLinks.title"), path: PATHS.ACCOUNT_LINKS },
    {
      label: t("forms.accountLinks.titleResume"),
      path: `/user/accountLinks/resume/${uuid}`,
    },
    { label: t("forms.accountLinks.titleDetail") },
  ];

  const [filters, setFilters] = useState({ uuid, ceco, account });

  const type = OPERATION_TYPES.find((t) => t.code === resume.type);
  // const handlerCleanFilter = async (resetForm) => {
  //   resetForm();
  //   setFilters(undefined);
  // };

  // const handlerOnSubmitFilter = (values) => {
  //   let newFilter = {};
  //   const valuesFilters = Object.entries(values);
  //   valuesFilters.forEach((value) => {
  //     if (value[1] !== undefined && value[1].toString().length > 0) {
  //       newFilter[value[0]] = value[1];
  //     }
  //   });

  //   setFilters(newFilter);
  // };

  const goBack = async () => {
    navigate(`/user/accountLinks/resume/${uuid}`);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.accountLinks.titleDetail")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-4 mt-2">
              <Container>
                <div className="row">
                  <div className="col-12 d-flex">
                    <LabelRes>
                      {t("forms.accountLinks.labels.companyCode")}:
                    </LabelRes>
                    <ValueRes>{resume.companyCode}</ValueRes>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    <LabelRes>{t("forms.accountLinks.labels.ceco")}:</LabelRes>
                    <ValueRes>{resume.ceco}</ValueRes>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    <LabelRes>
                      {t("forms.accountLinks.labels.account")}:
                    </LabelRes>
                    <ValueRes>{resume.account}</ValueRes>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    <LabelRes>{t("forms.accountLinks.labels.type")}:</LabelRes>
                    <ValueRes>{type ? t(type.label) : type.label}</ValueRes>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    <LabelRes>
                      {t("forms.accountLinks.labels.period")}:
                    </LabelRes>
                    <ValueRes>{`${resume.month}/${resume.year}`}</ValueRes>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    <LabelRes>
                      {t("forms.accountLinks.labels.totalAmount")}:
                    </LabelRes>
                    <ValueRes>{centsToMoney(resume.amount)}€</ValueRes>
                  </div>
                </div>
              </Container>
            </div>
            <div className="col-8">
              <div className="row justify-content-center">
                <div className="col-12">
                  <QueryClientProvider client={queryClient}>
                    <List
                      className="marg-top-1rem"
                      filters={filters}
                      uuid={uuid}
                    />
                  </QueryClientProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-4 text-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => goBack()}
          >
            {t("forms.accountLinks.buttons.backResume")}
          </button>
        </div>
      </div>
    </>
  );
};

AccountDetailFilterListContainer.propTypes = {};

export default AccountDetailFilterListContainer;
