import React, { useState } from "react";
import * as AccountServices from "../../../../services/AccountServices";
import PaginateList from "../../../utils/PaginateList";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { centsToMoney } from "../../../../utils/formats/number";

const AccountDetailListContainer = ({ filters }) => {
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.accountLinks.table.date"),
      accessorKey: "dateLine",
      cell: (info) => moment(info.getValue()).format("DD-MM-yyyy"),
    },
    {
      header: t("forms.accountLinks.table.amount"),
      accessorKey: "amount",
      cell: (info) => {
        if (info.row.original.amount) {
          return `${centsToMoney(info.row.original.amount)}€`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.accountLinks.table.period"),
      accessorKey: "month",
      cell: (info) => {
        if (info.row.original.month) {
          return `${info.row.original.month}/${info.row.original.year}`;
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.accountLinks.table.employeeCode"),
      accessorKey: "employeeCode",
      cell: (info) => info.getValue(),
    },
  ];

  const listData = async (filter) => {
    const result = await AccountServices.listDetailsFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;
  return (
    <PaginateList
      refresh={forceRefresh}
      filters={filters}
      columns={columns}
      toList={listData}
      hideColum={hideColumn}
      resize={resize}
      resetRefresh={() => setForceRefresh(false)}
    ></PaginateList>
  );
};

export default AccountDetailListContainer;
