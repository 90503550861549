import Push from "push.js";
import * as UserService from "../services/UserServices";

export const ShowNotification = (text) => {
  Push.create("Asientos contables", {
    body: text,
    icon: "/logo192.png",
    timeout: 4000,
    onClick: function () {
      window.focus();
      this.close();
    },
  });
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const checkPermissionNotification = () => {
  let serviceWorkerRegistration;
  Notification.requestPermission()
    .then((permission) => {
      return permission;
    })
    .then((hasPermission) => {
      if (hasPermission === "granted") {
        navigator.serviceWorker.ready
          .then((registration) => {
            serviceWorkerRegistration = registration;
            return registration.pushManager.getSubscription();
          })
          .then((subscription) => {
            console.log("subscription");
            console.log(subscription);
            if (subscription === null) {
              return serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                  "BKm6zRky9aGxtlhwseylmAomWcCAqVNxjvy_6hpofvYHJxknYAuvoG0JchTtlw9RLwrsrgGBhbven8MClY4GNyE"
                ),
              });
            } else {
              return null;
            }
          })
          .then((pushSubscription) => {
            if (pushSubscription) {
              console.log("ENvia");
              UserService.setNotificationSubscription(pushSubscription).then(
                () => window.location.reload()
              );
            }
          });
      }
    });
};
