import { createSelector } from "reselect";

const getStateNavSelector = (state) => state.nav;

export const getLanguageSelector = createSelector(
  getStateNavSelector,
  (nav) => {
    if (nav?.language) {
      return nav.language;
    }

    return undefined;
  }
);

export const getLineSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav?.line) {
    return nav.line;
  }

  return undefined;
});
