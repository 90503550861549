import LoginForm from "./login.view";

const LoginContainer = () => {
  const handleSubmit = async () => {
    window.location.href = `${process.env.REACT_APP_LOGIN_URI}`;
  };

  return <LoginForm gotoLogin={handleSubmit}></LoginForm>;
};

export default LoginContainer;
