import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #000;
  padding: 15px 15px 25px 15px;
`;
export const SubTitle = styled.div`
  font-size: 1.2rem;
  text-decoration: underline;
`;

export const SelectContainer = styled.div`
  border: 1px solid #000;
  height: 350px;
  padding: 15px;
`;

export const SelectContainer2 = styled.div`
  border: 0px solid #000;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const RowSelect = styled.div`
  font-size: 0.87rem;
  /* margin-bottom: 5px; */
  padding-top: 6px;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.button`
  font-size: 1.2rem;
  border: 0px solid #000;
  background-color: transparent;
  color: #f9ae17;

  &:hover {
    color: #203b34;
  }
`;

export const Subtitle = styled.div`
  font-size: 1.5rem;
  text-decoration: underline;
`;

export const ContainerTooltip = styled.div`
  max-width: 300px;
`;

export const ButtonSelect = styled.button`
  font-size: 0.875rem;
  border: 0px solid #000;
  background-color: transparent;
  color: #203b34;

  &:hover {
    color: #f9ae17;
    text-decoration: underline;
  }
`;
