import React from "react";
import AccountList from "../../../components/user/accountlink/accountFilterList";
import Layout from "../../../components/layouts/verticalLayout";

const AccountListPage = () => {
  return <Layout body={<AccountList />} />;
};

AccountListPage.propTypes = {};

export default AccountListPage;
