import React from "react";
import { Modal } from "react-bootstrap";
import { connectModal } from "redux-modal";
import PropTypes from "prop-types";

const ModalForm = ({ show, handleHide, body, backdrop, className }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      centered
      backdrop={backdrop || true}
      keyboard={false}
      className={className}
    >
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

ModalForm.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
};

export default connectModal({ name: "ModalForm" })(ModalForm);
