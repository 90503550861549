import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CecoContainer,
  Title,
  Body,
  SubTitle,
  Data,
  SubTitleFooter,
  PdfContainer,
  DetailButton,
} from "./accountResumeList.styled";
import moment from "moment-timezone";
import { centsToMoney } from "../../../../utils/formats/number";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import PdfView from "./pdf.view";
import html2PDF from "jspdf-html2canvas";

const AccountResume = (props) => {
  const { t } = useTranslation();
  const { data, onSelectLine } = props;
  const dispatch = useDispatch();

  const printTags = async (data) => {
    const date = new Date();
    const pages = document.getElementById("pdfData");
    // const pages = document.getElementsByClassName("page");
    await html2PDF(pages, {
      jsPDF: {
        format: "a4",
        autoPaging: "text",
      },
      autoPaging: "text",
      margin: {
        top: 30,
        right: 20,
        bottom: 30,
        left: 20,
      },
      html2canvas: {
        pageBreak: { mode: ["legacy"] },
        autoPaging: "text",
      },
      imageType: "image/jpeg",
      output: `${moment(date).format("DDMMYYYYhhmmss")}`,
    });
  };

  const generateTagsTemplate = (data) => {
    return (
      <>
        <PdfContainer>
          <div
            id="pdfData"
            className="mt4"
            style={{
              backgroundColor: "#fff",
              width: "210mm",
              minHeight: "287mm",
              margin: "auto",
            }}
          >
            <PdfView data={data}></PdfView>
          </div>
        </PdfContainer>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <button
              type="button"
              className="btn btn-md btn-primary"
              title={t("download")}
              onClick={() => printTags(data)}
            >
              {t("download")}
            </button>
          </div>
        </div>
      </>
    );
  };

  const showModalTags = () => {
    dispatch(
      show("ModalForm", {
        body: <>{generateTagsTemplate(data)}</>,
        className: "tagModal",
      })
    );
  };

  const renderLines = (lines, ceco) => {
    return lines.map((line) => {
      return (
        <div className="row" key={line.uuid}>
          <Data className="col">
            {moment(new Date(line.dateLine)).format("DD-MM-yyyy")}
          </Data>
          <Data className="col text-end">
            {line.type === "D" ? `${centsToMoney(line.amount)}€` : ""}
          </Data>
          <Data className="col">
            {line.type === "D" ? (
              <DetailButton
                type="button"
                onClick={() => onSelectLine(line, ceco)}
              >
                {line.account}
              </DetailButton>
            ) : (
              ""
            )}
          </Data>
          <Data className="col-3">{line.concept}</Data>
          <Data className="col">
            {line.type === "H" ? (
              <DetailButton
                type="button"
                onClick={() => onSelectLine(line, ceco)}
              >
                {line.account}
              </DetailButton>
            ) : (
              ""
            )}
          </Data>
          <Data $last className="col text-end">
            {line.type === "H" ? `${centsToMoney(line.amount)}€` : ""}
          </Data>
        </div>
      );
    });
  };

  const renderCecoLines = () => {
    return data.map((dataCeco) => {
      return (
        <div className="row mb-4" key={dataCeco.ceco}>
          <CecoContainer className="col-12">
            <div className="row">
              <Title className="col-12">{dataCeco.ceco}</Title>
            </div>
            <div className="row">
              <Body className="col-12">
                <div className="row">
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.date")}
                  </SubTitle>
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.amountDebe")}
                  </SubTitle>
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.ctaDebe")}
                  </SubTitle>
                  <SubTitle className="col-3">
                    {t("forms.accountLinks.labels.concept")}
                  </SubTitle>
                  <SubTitle className="col">
                    {t("forms.accountLinks.labels.ctaHaber")}
                  </SubTitle>
                  <SubTitle $last className="col">
                    {t("forms.accountLinks.labels.amountHaber")}
                  </SubTitle>
                </div>
                {renderLines(dataCeco.lines, dataCeco.ceco)}
                <div className="row">
                  <SubTitleFooter $last className="col">
                    {t("forms.accountLinks.labels.totalDebe")}
                  </SubTitleFooter>
                  <SubTitleFooter $last className="col text-end">
                    {`${centsToMoney(dataCeco.totalDebe)}€`}
                  </SubTitleFooter>
                  <SubTitleFooter $last className="col"></SubTitleFooter>
                  <SubTitleFooter $last className="col-3"></SubTitleFooter>
                  <SubTitleFooter $last className="col">
                    {t("forms.accountLinks.labels.totalHaber")}
                  </SubTitleFooter>
                  <SubTitleFooter $last className="col text-end">
                    {`${centsToMoney(dataCeco.totalHaber)}€`}
                  </SubTitleFooter>
                </div>
              </Body>
            </div>
          </CecoContainer>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mb-3 text-end">
          <button
            type="button"
            onClick={() => showModalTags()}
            className="btn btn-md btn-secondary me-2"
          >
            {t("forms.accountLinks.buttons.generatePdf")}
          </button>
        </div>
      </div>
      <div className="row no-margin">
        <div className="col-12 mb-3">{data && renderCecoLines()}</div>
      </div>
    </>
  );
};

AccountResume.propTypes = {
  data: PropTypes.any,
};

export default AccountResume;
