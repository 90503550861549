import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./accountGenerateForm.data.form";
// import InputText from "../../../utils/InputText";
import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { MONTHS } from "../../../../constants/index";
import {
  SelectContainer,
  SelectContainer2,
  RowSelect,
  Button,
  Container,
  SubTitle,
  ButtonSelect,
} from "./accountGenerateForm.styled";

const GenerateForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    cancelAction,
    companies,
    companiesSelected,
    onDelete,
    onSelect,
    onSelectAll,
    onDeleteAll,
  } = props;
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.accountLinks.title"), path: PATHS.ACCOUNT_LINKS },
    { label: t("forms.accountLinks.titleGenerate") },
  ];

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < 5; index++) {
    years.push({
      code: currentYear - index,
      label: currentYear - index,
    });
  }

  const renderCompaniesAllowed = () => {
    return companies.map((company) => {
      return (
        <div className="row  no-margin" key={`allow_${company.code}`}>
          <div className="col-12">
            <RowSelect>
              <div>{`${company.code} ${company.name}`}</div>
              <Button onClick={() => onSelect(company)}>
                <i className="fa-solid fa-square-plus"></i>
              </Button>
            </RowSelect>
          </div>
        </div>
      );
    });
  };

  const renderCompaniesSelected = () => {
    return companiesSelected.map((company) => {
      return (
        <div className="row no-margin" key={`selected_${company.code}`}>
          <div className="col-12">
            <RowSelect>
              <div>{`${company.code} ${company.name}`}</div>
              <Button onClick={() => onDelete(company)}>
                <i className="fa-solid fa-square-minus"></i>
              </Button>
            </RowSelect>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.accountLinks.titleGenerate")}</h3>
        </div>
      </div>

      <Breadcrumb items={breads} />
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-4">
                <div className="row mb-3">
                  <div className="col-12">
                    <SubTitle>{t("forms.accountLinks.selectPeriod")}</SubTitle>
                  </div>
                </div>
                <Container>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        id="month"
                        name="month"
                        labelField={t("forms.accountLinks.labels.month")}
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={MONTHS.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        classNamePrefix="dark"
                        addOptionEmpty
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        id="year"
                        name="year"
                        labelField={t("forms.accountLinks.labels.year")}
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={years.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        classNamePrefix="dark"
                        addOptionEmpty
                      />
                    </div>
                  </div>
                </Container>
              </div>
              <div className="col-8">
                <div className="row mb-3">
                  <div className="col-12">
                    <SubTitle>{t("forms.accountLinks.selectCompany")}</SubTitle>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <SelectContainer>
                      <SelectContainer2>
                        {companies && renderCompaniesAllowed()}
                      </SelectContainer2>
                    </SelectContainer>
                  </div>
                  <div className="col-6">
                    <SelectContainer>
                      <SelectContainer2>
                        {companiesSelected && renderCompaniesSelected()}
                      </SelectContainer2>
                    </SelectContainer>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6 text-end pe-0">
                    <ButtonSelect
                      type="button"
                      onClick={() => onSelectAll()}
                      className="me-2"
                    >
                      <i className="fa-regular fa-square-plus me-1"></i>
                      {t("forms.accountLinks.buttons.selectAll")}
                    </ButtonSelect>
                  </div>
                  <div className="col-6 text-end pe-0">
                    <ButtonSelect
                      type="button"
                      onClick={() => onDeleteAll()}
                      className="me-2"
                    >
                      <i className="fa-regular fa-trash-can me-1"></i>
                      {t("forms.accountLinks.buttons.deleteAll")}
                    </ButtonSelect>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-5">
              <div className="col-12 text-center">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.accountLinks.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.accountLinks.buttons.generate")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

GenerateForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const GenerateFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "GenerateForm",
})(GenerateForm);

export default GenerateFormF;
