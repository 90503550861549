export const PAGE_SIZE_10 = 10;
export const CLEAN_AUTH = "CLEAN_AUTH";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_PROFILE = "GET_PROFILE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const CLEAN_NAV = "CLEAN_NAV";
export const PREREGISTER_EMPLOYEE = "PREREGISTER_EMPLOYEE";
export const SET_DATA_LINE = "SET_DATA_LINE";

export const ROLE_TECHNICAL = "technical";
export const ROLE_USER_RRHH = "userRRHH";
export const ROLE_ADMIN_RRHH = "adminRRHH";
export const ROLE_EXTERNAL = "external";
export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";

export const LANGUAGE_ES = {
  code: "es",
  label: "language.languages.es",
};
export const LANGUAGE_EN = {
  code: "en",
  label: "language.languages.en",
};
export const LANGUAGE_PT = {
  code: "pt",
  label: "language.languages.pt",
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_PT, LANGUAGE_EN];

export const IDEN_TYPE_A3_DNI = {
  code: 1,
  label: "identificationType.dni",
};

export const MONTHS = [
  {
    code: 1,
    label: "months.jan",
  },
  {
    code: 2,
    label: "months.feb",
  },
  {
    code: 3,
    label: "months.mar",
  },
  {
    code: 4,
    label: "months.apr",
  },
  {
    code: 5,
    label: "months.may",
  },
  {
    code: 6,
    label: "months.jun",
  },
  {
    code: 7,
    label: "months.jul",
  },
  {
    code: 8,
    label: "months.ago",
  },
  {
    code: 9,
    label: "months.sep",
  },
  {
    code: 10,
    label: "months.oct",
  },
  {
    code: 11,
    label: "months.nov",
  },
  {
    code: 12,
    label: "months.dec",
  },
];

export const ACCOUNT_STATUS_PENDING = {
  code: "PENDING",
  label: "forms.accountLinks.status.pending",
};
export const ACCOUNT_STATUS_GENERATED = {
  code: "GENERATED",
  label: "forms.accountLinks.status.generated",
};
export const ACCOUNT_STATUS_DELIVERED = {
  code: "DELIVERED",
  label: "forms.accountLinks.status.delivered",
};
export const ACCOUNT_STATUS_ERROR = {
  code: "ERROR",
  label: "forms.accountLinks.status.error",
};

export const ACCOUNT_STATUS = [
  ACCOUNT_STATUS_PENDING,
  ACCOUNT_STATUS_GENERATED,
  ACCOUNT_STATUS_DELIVERED,
  ACCOUNT_STATUS_ERROR,
];

export const OPERATION_TYPE_DEBE = {
  code: "D",
  label: "forms.accountLinks.operationType.debe",
};
export const OPERATION_TYPE_HABER = {
  code: "H",
  label: "forms.accountLinks.operationType.haber",
};

export const OPERATION_TYPES = [OPERATION_TYPE_DEBE, OPERATION_TYPE_HABER];
