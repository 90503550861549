import styled from "styled-components";

export const PdfContainer = styled.div`
  max-height: 500px;
  overflow: auto;
  /* overflow-x: hidden; */
  border: 1px solid #ccc;
  padding: 20px;
  -webkit-box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
`;

export const DetailButton = styled.button`
  font-size: 0.875rem;
  color: #f9ae17;
  border: 0px;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
`;

export const CecoContainer = styled.div`
  font-size: 0.875rem;
  /* padding: 25px; */
  border: 1px solid #000;
`;

export const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 15px;
  border-bottom: 1px solid #000;
  background-color: #203b34;
  color: #f9ae17;
`;

export const SubTitle = styled.div`
  font-size: 0.87rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 15px;
  border-bottom: 1px solid #000;
  border-right: ${({ $last }) => ($last ? "0px" : "1px solid #000")};
  /* border: 1px solid #000; */
`;

export const Body = styled.div`
  font-size: 0.875rem;
  /* padding: 25px; */
  /* border: 1px solid #000; */
`;

export const Data = styled.div`
  font-size: 0.7rem;
  border-right: ${({ $last }) => ($last ? "0px" : "1px solid #000")};
`;

export const SubTitleFooter = styled.div`
  font-size: 0.87rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 15px;
  border-top: 1px solid #000;
  border-right: ${({ $last }) => ($last ? "0px" : "1px solid #000")};
  /* border: 1px solid #000; */
`;
